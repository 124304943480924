var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-account","items":_vm.availableMembers,"single-line":"","dense":"","clearable":""},model:{value:(_vm.member),callback:function ($$v) {_vm.member=$$v},expression:"member"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('tracker-selector',{attrs:{"prepend-icon":"mdi-bug","dense":"","single-line":"","type":"name","clearable":""},model:{value:(_vm.tracker),callback:function ($$v) {_vm.tracker=$$v},expression:"tracker"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","offset-md":"6"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-calendar","items":_vm.availableYears,"dense":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1)],1)],1),_c('v-card-text',[(_vm.project)?_c('v-data-table',{attrs:{"calculate-widths":true,"headers":_vm.cols,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.total",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(-1))+" h ")]}},{key:"header.0",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(0))+" h ")]}},{key:"header.1",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(1))+" h ")]}},{key:"header.2",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(2))+" h ")]}},{key:"header.3",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(3))+" h ")]}},{key:"header.4",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(4))+" h ")]}},{key:"header.5",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(5))+" h ")]}},{key:"header.6",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(6))+" h ")]}},{key:"header.7",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(7))+" h ")]}},{key:"header.8",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(8))+" h ")]}},{key:"header.9",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(9))+" h ")]}},{key:"header.10",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(10))+" h ")]}},{key:"header.11",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.totalByMonths(11))+" h ")]}},{key:"item.module",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.module))])]}},{key:"item.0",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.1",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.2",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.3",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.4",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.5",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.6",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.7",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.8",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.9",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.10",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.11",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("-")])]}},{key:"item.total",fn:function(ref){
var value = ref.value;
return [(value > 0)?_c('strong',{staticClass:"blue--text text--darken-1"},[_vm._v(_vm._s(value)+" h")]):_c('span',[_vm._v("-")])]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }