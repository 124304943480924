<template>
    <v-row>
        <v-col cols="2">
            <analyticsDrawer />
        </v-col>
        <v-col cols="10">
            <analytics-issues-by-user v-if="activeMenu === '#issuesByUser'" :key="activeMenu" />
            <analytics-issues-by-tracker v-if="activeMenu === '#issuesByTracker'" :key="activeMenu" />
            <analytics-times-by-user v-if="activeMenu === '#timesByUser'" :key="activeMenu" />
        </v-col>
    </v-row>
</template>

<script>
import analyticsDrawer from '@/components/drawers/drawer-project-analytics';
import analyticsIssuesByUser from './analytics/issuesByUser';
import analyticsIssuesByTracker from './analytics/issuesByTracker';
import analyticsTimesByUser from './analytics/timesByUser';

export default {
    name: 'project-analytics',
    data: () => ({}),
    components: {
        analyticsDrawer,
        analyticsIssuesByUser,
        analyticsIssuesByTracker,
        analyticsTimesByUser,
    },
    created() {
        if (!this.$route.hash) {
            this.$router.push({ name: this.$route.name, hash: '#timesByUser' });
        }
    },
    computed: {
        activeMenu() {
            return this.$route.hash;
        },
    },
    methods: {},
};
</script>
