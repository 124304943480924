<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="3" v-for="(issue, index) in trackersToAnalyse" :key="'trackers' + index">
                <v-card :color="issue.group.color" dark>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="headline" v-text="$t('lists.trackers.' + issue.group.name)">
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-text>
                        <v-row align="center">
                            <v-col class="display-3" cols="6" v-text="issue.count"> </v-col>
                            <!--<v-col class="display-3" cols="6">
                                <v-icon size="56" color="grey lighten-2">mdi-bug</v-icon>
                            </v-col>-->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/utils/api';
import SortBy from 'lodash/sortBy';

export default {
    name: 'project-analytics-issuesByTrackers',
    data: () => ({
        loading: false,
        trackersToAnalyse: [],
        chart: null,
    }),
    computed: {
        ...mapGetters(['project', 'trackers']),
    },
    mounted() {
        if (this.project) {
            this.updateIssues();
        }
    },
    watch: {
        project: function (value) {
            if (value) {
                this.updateIssues();
            }
        },
    },
    methods: {
        async updateIssues() {
            this.loading = true;

            const query = [
                {
                    field: 'project',
                    operator: '$eq',
                    value: this.project.name,
                },
            ];

            const gettingCountTrackers = await API.post('analytics/count', { filters: query, group: 'tracker' });

            this.trackersToAnalyse = SortBy(
                gettingCountTrackers.data.datas
                    .map((i) => {
                        const tracker = this.trackers.find((t) => t.name === i.group);
                        return {
                            count: i.count,
                            group: tracker ? tracker : undefined,
                        };
                    })
                    .filter((i) => i.group),
                ['count'],
                ['desc'],
            ).reverse();

            this.loading = false;
        },
    },
};
</script>

<style></style>
