<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col cols="12" md="4">
                    <v-list two-line>
                        <v-subheader class="headline">{{ $t('projects.analytics.issuesByCreatedBy') }}</v-subheader>
                        <v-list-item v-for="(issue, index) in createdBy" :key="'createdBy' + index">
                            <v-list-item-icon>
                                <v-icon v-if="index === 0" color="yellow darken-2"> mdi-medal </v-icon>
                                <v-icon v-if="index === 1" color="grey darken-2"> mdi-medal </v-icon>
                                <v-icon v-if="index === 2" color="brown  darken-2"> mdi-medal </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="issue.group.fullname"></v-list-item-title>
                                <v-list-item-subtitle v-text="issue.count"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar>
                                <v-img :src="issue.group.avatar"></v-img>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="4" offset-md="3">
                    <v-list two-line>
                        <v-subheader class="headline">{{ $t('projects.analytics.issuesByAssignedTo') }}</v-subheader>
                        <v-list-item v-for="(issue, index) in assignedTo" :key="'assignedTo' + index">
                            <v-list-item-icon>
                                <v-icon v-if="index === 0" color="yellow darken-2"> mdi-medal </v-icon>
                                <v-icon v-if="index === 1" color="grey darken-2"> mdi-medal </v-icon>
                                <v-icon v-if="index === 2" color="brown  darken-2"> mdi-medal </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="issue.group.fullname"></v-list-item-title>
                                <v-list-item-subtitle v-text="issue.count"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar>
                                <v-img :src="issue.group.avatar"></v-img>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/utils/api';
import SortBy from 'lodash/sortBy';

export default {
    name: 'project-analytics-issuesByUser',
    data: () => ({
        loading: false,
        createdBy: [],
        assignedTo: [],
        chart: null,
    }),
    computed: {
        ...mapGetters(['project', 'usersLabels']),
    },
    mounted() {
        if (this.project) {
            this.updateIssues();
        }
    },
    watch: {
        project: function (value) {
            if (value) {
                this.updateIssues();
            }
        },
    },
    methods: {
        async updateIssues() {
            this.loading = true;

            const query = [
                {
                    field: 'project',
                    operator: '$eq',
                    value: this.project.name,
                },
            ];

            const gettingCountCreatedBy = await API.post('analytics/count', { filters: query, group: 'createdBy' });

            this.createdBy = SortBy(
                gettingCountCreatedBy.data.datas
                    .map((i) => {
                        const user = this.usersLabels.find((u) => u.name === i.group);
                        return {
                            count: i.count,
                            group: user ? user : undefined,
                        };
                    })
                    .filter((i) => i.group),
                ['count'],
                ['desc'],
            ).reverse();

            const gettingCountAssignedTo = await API.post('analytics/count', { filters: query, group: 'assignedTo' });

            this.assignedTo = SortBy(
                gettingCountAssignedTo.data.datas
                    .map((i) => {
                        const user = this.usersLabels.find((u) => u.name === i.group);
                        return {
                            count: i.count,
                            group: user ? user : undefined,
                        };
                    })
                    .filter((i) => i.group),
                ['count'],
                ['desc'],
            ).reverse();

            this.loading = false;
        },
    },
};
</script>

<style></style>
